<template>
    <div class="conditions" id="conditions">
        <div class="row">
            <div class="col-12">
                <h3 class="block-title">Conditions</h3>
            </div>
            <div class="col-12 col-md-8 conditions-list">
                <span v-for="condition in conditions">
                    <b>
                        <NuxtImg src="/img/check.svg" width="20px" height="20px" class="mr-2" />
                        {{ condition.title }}
                    </b> <br>
                    <span>{{ condition.description }}</span>
                </span>
            </div>
            <div class="col-12 col-md-4 image-wrap">
                <img src="/img/cook.png" alt="cook">
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const conditions = [
    {title: 'Unlimited Menus:', description: 'Create as many menus as you need, with no restrictions.'},
    {title: 'Team Size:', description: 'Add unlimited team members to manage your menus.'},
    {title: 'Image Storage:', description: 'Up to 200MB of image storage for your menu items.'},
    {
        title: 'No Credit Card Required:',
        description: 'Start using our platform without any upfront payment information.'
    },
];

</script>

<style scoped lang="scss">
@import '~/assets/css/rfs.scss';

.conditions {
}

.conditions-list {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
        @include font-size(1.5rem);
        @include line-height(1.6rem);
        @include margin-bottom(1.2rem);

        b {
            display: inline-flex;
            font-family: "Raleway";
        }

        span {
            padding-left: calc(20px + 0.5rem);
        }
    }
}

.image-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>