<template>
    <div class="advantages mb-2" id="advantages">
        <div class="row">
            <div class="col-12">
                <h3 class="block-title">Advantages</h3>
            </div>

            <div v-for="advantage in advantages" class="col-12 col-md-6 col-lg-3 mb-3">
                <div class="advantage" :style="advantage.style" :class="advantage.class">
                    <Icon :name="advantage.icon" size="50px" />
                    <div class="advantage__title">
                        {{ advantage.title }}
                    </div>
                    <div class="advantage__description" v-html="advantage.description"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup lang="ts">
const advantages = [
    {
        class: 'book',
        icon: 'streamline-emojis:green-book',
        title: 'Update Flexibility',
        description: 'Update your menu in real-time for price changes or new items, enhancing operational efficiency.',
        style: {background: 'linear-gradient(210deg, rgba(35, 34, 40, 0.80) 0%, #19C37D 100%)'},
    },
    {
        class: 'wings',
        icon: 'streamline-emojis:money-with-wings',
        title: 'Cost Savings',
        description: 'Save on printing costs with a digital menu, ensuring economic and efficient menu management.',
        style: {background: 'linear-gradient(270deg, rgba(35, 34, 40, 0.80) 0%, #19C37D 100%)'},
    },
    {
        class: 'selfie',
        icon: 'streamline-emojis:selfie-2',
        title: 'Convenience',
        description: 'Enhance customer experience with easy QR code access, promoting health and hygiene standards.',
        style: {background: 'linear-gradient(213deg, rgba(35, 34, 40, 0.80) 0%, #19C37D 100%)'},
    },
    {
        class: 'leaf',
        icon: 'streamline-emojis:leaf-fluttering-in-wind',
        title: 'Eco-Friendlier',
        description: 'Contribute to environmental care with less paper use, reflecting your commitment to eco-friendliness.',
        style: {background: 'linear-gradient(206deg, rgba(35, 34, 40, 0.80) 0%, #19C37D 100%)'},
    }
];
</script>

<style scoped lang="scss">
@import '~/assets/css/rfs.scss';

.advantage {
    height: 100%;
    display: flex;
    padding: 15px;
    border-radius: 8px;
    flex-direction: column;
    background-color: #29282F;

    .icon {
        margin-bottom: 15px;
    }

    .advantage__title {
        display: inline-flex;
        font-weight: 600;
        font-family: Manrope;
        @include font-size(1.5rem);
        @include line-height(1.6rem);
        @include margin-bottom(0.8rem);
    }

    .advantage__description {
        color: #f1f1f1;
        font-weight: 600;
        word-wrap: break-word;
        @include font-size(1.1rem);
        @include line-height(1.6rem);
    }

    &.book {
        .icon {
            margin-left: -8px;
        }
    }
}

</style>
