<template>
    <div class="faq" id="faq">
        <h3 class="block-title">Frequently Asked Questions</h3>

        <div class="row">
            <div v-for="question in questions" class="col-12">
                <details>
                    <summary>
                        {{ question.summary }}

                        <Icon name="material-symbols:keyboard-arrow-down-rounded" color="#f1f1f1" size="30px" alt="" />
                    </summary>
                    <p v-html="question.answer"></p>
                </details>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const questions = [
    {
        summary: 'Is the Online Menu Editor fully operational?',
        answer: 'Our Online Menu Editor is currently in beta testing. Most features are operational, and we welcome feedback or inquiries via email for further improvement.'
    },
    {
        summary: 'How quickly can a menu be created?',
        answer: 'Using our standard templates, you can create a menu within a few hours. Designing a custom menu takes longer, depending on your specific preferences and availability.'
    },
    {
        summary: 'Are special skills required to use the editor?',
        answer: 'No advanced skills are needed. Familiarity with basic word processing is sufficient. For assistance, feel free to reach out via email or consult our forthcoming user guide.'
    },
    {
        summary: 'How do QR codes facilitate menu access?',
        answer: 'QR codes for menus can be downloaded from your account. They can be used digitally or printed for physical display in your establishment.'
    },
    {
        summary: 'Is real-time menu updating possible?',
        answer: 'Absolutely, you can edit and publish your menu instantly. Users will see the updated version upon refreshing their view.'
    },
    {
        summary: 'What is the cost of using this editor?',
        answer: 'Currently, the editor is free to use. We may revise our pricing based on user volume in the future, but it will remain accessible for establishments of all sizes.'
    },
];

</script>

<style scoped lang="scss">
@import '~/assets/css/rfs.scss';

details {
    padding: 10px 20px;
    user-select: none;
    margin-bottom: 3px;
    background-color: #29282F;

    summary {
        display: flex;
        padding: 15px 0;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @include font-size(1.3rem);
        @include line-height(1.3rem);
    }

    p {
        padding: 15px 0;
    }

    &[open] {
        summary {
            svg {
                transform: rotate(180deg);
            }
        }

        p {
            border-top: 1px solid darkgray;
        }
    }
}
</style>
