<template>
    <div class="hero pt-5 pb-3" id="about">
        <div class="row">
            <div class="col-12 col-md-7 mb-4">
                <h1>
                    OnLine.menu - Efficient way of managing your restaurant
                </h1>

                <p>
                    Discover the simplicity of digital menu management with OnLine.menu. Effortlessly create, update,
                    and share your restaurant's menu in real-time, harnessing the power of modern technology!
                </p>
            </div>

            <div class="col-12 col-md-5 mb-4 previews-wrapper relative">
                <div class="">
                    <div class="macbook-wrapper relative">
                        <img
                            width="320px"
                            alt="menu-preview"
                            class="macbook-content"
                            src="/img/menu_example_laptop_2.png"
                        />

                        <img src="/img/macbook.png" class="macbook" alt="menu-preview" />
                    </div>
                </div>

                <div class="iphone-position-wrapper">
                    <div class="iphone-wrapper relative">
                        <img src="/img/menu-example.png" class="iphone-content" width="128px" alt="menu-preview" />
                        <img src="/img/iphone.png" class="iphone" width="128px" alt="menu-preview" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@import '~/assets/css/rfs.scss';
@import '~/assets/css/_include-media.scss';

$iphone-width: 100px;
$laptop-width: 420px;

.hero {
    h1 {
        color: white;
        font-weight: 800;
        font-family: Manrope;
        word-wrap: break-word;
        @include font-size(45px);
        @include margin-top(20px);
        @include line-height(47px);
        @include margin-bottom(30px);
    }

    p {
        color: white;
        font-weight: 500;
        font-family: Manrope;
        word-wrap: break-word;
        @include font-size(18px);
        @include line-height(24px);
    }
}

.previews-wrapper {
    display: flex;
}

.iphone-wrapper {
    overflow: hidden;
    position: relative;
    width: $iphone-width;

    .iphone-content {
        top: 2px;
        left: 2px;
        right: 2px;
        z-index: 1;
        bottom: 2px;
        position: absolute;
        border-radius: 15px;
        background-color: #fff;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
    }

    .iphone {
        z-index: 2;
        position: relative;
        width: $iphone-width;
    }
}

.iphone-position-wrapper {
    top: 50px;
    right: 50px;
    z-index: 10;
    position: absolute;
}

.macbook-wrapper {
    width: $laptop-width;
    overflow: hidden;
    position: relative;

    .macbook-content {
        top: 2px;
        left: 10%;
        right: 10%;
        z-index: 1;
        position: absolute;
        border-radius: 15px;
        background-color: #fff;
        width: 80%;
        height: auto;
    }

    .macbook {
        z-index: 2;
        width: $laptop-width;
        position: relative;
    }
}

@include media("<=phone") {
    $iphone-width: 80px;
    $laptop-width: 80vw;

    .previews-wrapper {
        margin-top: 20px;
        padding-left: 10vw;
    }

    .iphone-position-wrapper {
        top: 20px;
    }

    .macbook-wrapper {
        width: $laptop-width;

        .macbook {
            width: $laptop-width;
        }
    }
}
</style>